<style>
.filter {
  display: flex;
  align-items: center;
}
.card.card-custom > .card-header {
  flex-direction: column;
}
</style>
<template>
  <!--begin::Advance Table Widget 10-->
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <!-- <a
      @click="goToHome('next')"
      class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
    >
      Next
    </a>
    <a
      @click="goToHome('prev')"
      class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
    >
      Prev
    </a> -->
    <!-- <button @click="goToHome()">Next</button>
    <button>Prev</button> -->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Overview Line {{ lineId }}</span
        >
        <!-- <span class="d-flex d-sm-none mt-3 font-size-sm">Spare parts : 
          <p class="text-warning"> {{getTotalOrange}} Orange</p> - <p class="text-danger"> {{getTotalRed}} Red</p></span>
        <span class="d-flex d-sm-none  mt-3 font-weight-bold font-size-sm"></span>
        <span class="d-none d-sm-flex text-warning mt-3 font-weight-bold font-size-sm">{{getTotalOrange}} Orange</span>
        <span class="d-none d-sm-flex text-danger mt-3 font-weight-bold font-size-sm">{{getTotalRed}} Red</span> -->
      </h3>
      <!-- <div class="card-toolbar">
        <a href="#" class="btn btn-info font-weight-bolder font-size-sm"
          >New Report</a
        >
      </div> -->

      <div class="filter">
         <b-form-group id="input-group-2" label="" label-for="input-2">
       <b-form-input
         id="input-2"
         v-model="form.name"
         v-on:keyup="(e) => FilterData(e.target.value)"
         placeholder="Search"
       ></b-form-input>
     </b-form-group>
        <b-button @click="submit('green')" :class="(active == 'green')?' active':''" variant="success">Green</b-button>
        <b-button @click="submit('orange')" :class="(active == 'orange')?' active':''" variant="warning">Orange</b-button>
        <b-button @click="submit('red')" :class="(active == 'red')?' active':''" variant="danger">Red</b-button>
        <b-button @click="submit('all')">Clear</b-button>
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-0">
      <b-progress
        :max="getTotalGreen + getTotalOrange + getTotalRed"
        show-value
      >
        <b-progress-bar
          :value="getTotalGreen"
          variant="success"
        ></b-progress-bar>
        <b-progress-bar
          :value="getTotalOrange"
          variant="warning"
        ></b-progress-bar>
        <b-progress-bar :value="getTotalRed" variant="danger"></b-progress-bar>
      </b-progress>
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_4"
        >
          <thead>
            <tr class="text-left">
              <th class="pl-0" style="width: 55px">Status</th>
              <th style="min-width: 55px; max-width: 80px">Line</th>
              <th style="min-width: 140px">
                {{ $t("TRANSLATOR.NAME", { msg: "NAME" }) }}
              </th>
              <th style="min-width: 100px">
                {{ $t("TRANSLATOR.SECTION", { msg: "SECTION" }) }}
              </th>
              <!-- <th style="min-width: 60px; max-width: 120px">Code</th> -->
              <!-- <th class="pr-0 text-right" style="min-width: 60px; max-width: 75px">Length</th> -->
              <th class="pr-0" style="min-width: 100px">Last check</th>
              <!-- <th class="pr-0 text-right" style="min-width: 60px; max-width: 100px">Status</th> -->
              <th
                class="pl-0 text-center"
                style="min-width: 20px; max-width: 40px"
              >
                <span class="label label-lg label-inline label-light-success">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Navigation/Check.svg" />
                  </span>
                </span>
              </th>
              <th
                class="pl-0 text-center"
                style="min-width: 20px; max-width: 40px"
              >
                <span class="label label-lg label-inline label-light-warning">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/Code/warning-1-circle.svg"
                    />
                  </span>
                </span>
              </th>
              <th
                class="pl-0 text-center"
                style="min-width: 20px; max-width: 40px"
              >
                <span class="label label-lg label-inline label-light-danger">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Code/Warning-2.svg" />
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in lineInfo">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <span
                    v-if="item.statusRed === 0 && item.statusOrange === 0"
                    class="label label-lg label-inline label-light-success"
                  >
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/Navigation/Check.svg" />
                    </span>
                  </span>
                  <span
                    v-if="item.statusRed === 0 && item.statusOrange > 0"
                    class="label label-lg label-inline label-light-warning"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Code/warning-1-circle.svg"
                      />
                    </span>
                  </span>
                  <span
                    v-if="item.statusRed > 0"
                    class="label label-lg label-inline label-light-danger"
                  >
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/Code/Warning-2.svg" />
                    </span>
                  </span>
                </td>
                <td>
                  <a
                    v-bind:href="`#/line/${lineId}/conveyor/${item.conveyorNumber}`"
                    class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                  >
                    {{ item.lineNumber }}
                  </a>
                </td>
                <td>
                  <a
                    v-bind:href="`#/line/${lineId}/conveyor/${item.conveyorNumber}`"
                    class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                  >
                    {{ item.conveyorNumber }} > {{ item.conveyorExtName }}
                  </a>
                </td>
                <td>
                  <a
                    v-bind:href="`#/line/${lineId}/conveyor/${item.conveyorNumber}`"
                    class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                  >
                    <!-- <span class="text-grey font-weight-bolder d-block font-size-lg"> -->
                    <!-- {{ item.conveyorName }} -->
                    {{ item.sortOrder }}
                    <!-- </span> -->
                  </a>
                </td>
                <!-- <td>
                  <span class="text-muted font-weight-bold">{{item.code}}</span>
                </td> -->
                <!-- <td class="pr-0 text-right">
                  <span class="text-muted font-weight-bold">{{item.length}} m</span>
                </td> -->
                <td class="pr-0">
                  <span class="text-muted font-weight-bold">{{
                    item.updated
                  }}</span>
                </td>
                <td class="text-center">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                  </span>
                  <span class="text-muted font-weight-bold">{{
                    item.statusGreen
                  }}</span>
                </td>
                <td class="text-center">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                  </span>
                  <span class="text-muted font-weight-bold">{{
                    item.statusOrange
                  }}</span>
                </td>
                <td class="text-center">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                  </span>
                  <span class="text-muted font-weight-bold">{{
                    item.statusRed
                  }}</span>
                </td>
                <!-- <td class="pr-0 text-right">
                  <span class="label label-lg label-inline" v-bind:class="`label-light-${item.class}`">{{ item.status }}</span>
                </td> -->
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>

    <!-- Start of loading animation -->
    <div class="mt-40" v-if="loading">
      <p class="text-6xl font-bold text-center text-gray-500 animate-pulse">
        Loading...
      </p>
    </div>
    <!-- End of loading animation -->
    <!-- Start of error alert -->
    <div class="mt-12 bg-red-50" v-if="error">
      <h3 class="px-4 py-1 text-4xl font-bold text-white bg-red-800">
        {{ error.title }}
      </h3>
      <p class="p-4 text-lg font-bold text-red-900">{{ error.message }}</p>
    </div>
    <!-- End of error alert -->
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 10-->
</template>

<script>
import axios from "axios";

export default {
  name: "widget-1",
  props: {
    lineId: String,
    // value: String
  },
  data() {
    return {
      checked: false,
      loading: false,
      active: '',
      error: null,
      lineInfo: [],
      filterData: [],
      greenData: [],
      redData: [],
      orangeData: [],
      filterName: "5",
      selected: null,
      options: [
        { value: null, text: "Filter data" },
        { value: "red", text: "Red" },
        { value: "green", text: "Green" },
        { value: "orange", text: "Orange" },
      ],
      form: {
        name: "",
      },
      // list: [
      //   {
      //     id: "1",
      //     line_nr: "1",
      //     line_name: "Line 1",
      //     conveyor_name: "ITA11.2",
      //     type: "xlg1000ft-170mm pt-1dp",
      //     code: "10404695",
      //     length: "15.8",
      //     created: "05/01/2020",
      //     overdue: "05/01/2020",
      //     updated: "05/01/2020",
      //     class: "danger",
      //     status: "500"
      //   },
      //   {
      //     id: "2",
      //     line_nr: "1",
      //     line_name: "Line 1",
      //     conveyor_name: "ITA11.3",
      //     type: "xlg1000ft-170mm pt-1dp",
      //     code: "10404695",
      //     length: "19.8",
      //     created: "05/01/2020",
      //     overdue: "05/01/2020",
      //     updated: "05/01/2020",
      //     class: "warning",
      //     status: "400"
      //   },
      // ]
    };
  },
  computed: {
    getTotalOrange() {
      const total = this.lineInfo.reduce((sum, object) => {
        sum += object.statusOrange;
        return sum;
      }, 0);
      return total;
    },
    getTotalRed() {
      const total = this.lineInfo.reduce((sum, object) => {
        sum += object.statusRed;
        return sum;
      }, 0);
      return total;
    },
    getTotalGreen() {
      const total = this.lineInfo.reduce((sum, object) => {
        sum += object.statusGreen;
        return sum;
      }, 0);
      return total;
    },
  },
  components: {},
  // created() {
  //   console.log(this.lineId1);
  // },
  methods: {
    getStatusClass: (status) => {
      switch (status) {
        case "red":
          return "danger";
        case "orange":
          return "warning";
        case "green":
          return "success";
      }
    },
    goToHome(type) {
      if (type == "next") {
        this.lineId++;
        //  this.lineId = this.lineId + 1
      } else {
        this.lineId--;
        // this.lineId = this.lineId - 1
      }
      this.lineId = this.lineId;
      // console.log(this.lineId);
      this.$router.push("/line/" + this.lineId);

      axios
        .get("/line/" + this.lineId)
        .then((response) => {
          this.lineInfo = response.data;
          this.filterData = response.data;
          // console.log(this.lineInfo)
          // this.getTotal();
        })
        .catch((err) => {
          throw err;
        });
    },

    FilterData: function (e) {
      if (e.length >= 3) {
        let newData = this.filterData.filter(
          (item) =>
            item.conveyorExtName.includes(e) || item.sortOrder.includes(e)
        );
        this.lineInfo = newData;
      }
      if (e.length == 0) {
        this.lineInfo = this.filterData;
      }
    },
    submit: function (e) {
      this.active = e;
      if (e == "red") {
        let newData = this.filterData.filter((item) => item.statusRed > 0);
        this.lineInfo = newData;
      }
      if (e == "orange") {
        let newData = this.filterData.filter(
          (item) => item.statusRed === 0 && item.statusOrange > 0
        );
        this.lineInfo = newData;
      }
      if (e == "green") {
        let newData = this.filterData.filter(
          (item) => item.statusRed === 0 && item.statusOrange === 0
        );
        this.lineInfo = newData;
      }
      if (e == "all") {
        this.lineInfo = this.filterData;
      }
    },
  },
  mounted() {
    // this.fetchLineInfo();
    axios
      .get("/line/" + this.lineId)
      .then((response) => {
        // console.log(response.data)
        this.lineInfo = response.data;
        this.filterData = response.data;
        // console.log(this.lineInfo)
        // this.getTotal();
      })
      .catch((err) => {
        throw err;
      });
  },
};
</script>
