<template>
  <div>    
    <!-- <span>{{ TRANSLATOR.SELECT }}</span> -->
    <!--begin::Dashboard-->
    <LineTable :lineId="lineId"></LineTable>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import { TRANSLATOR } from "@/core/services/store/breadcrumbs.module";
// import KTWidget2 from "@/view/pages/dashboard/Widget2";
import LineTable from "@/view/pages/line/LineTable";

export default {
  name: "line-base",
  components: {
    // AdvancedTableWidget2
    // KTWidget2,
    LineTable,
  },  
  data() {
    return {
      lineId: this.$route.params.id,
    }
  },
  mounted() {
    // console.log(this.$route.params);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Home > Line " + this.lineId, route: `/home/`}]);
    // console.log(this.lineId);
  },
  methods: {
  }
};
</script>
